<template>
  <div class="">
    <redirect class="mb-5" title="Boost UpSell" route="website.upsell" />
    <div class="d-flex align-start justify-space-between">
      <h1 class="page-title">{{ $t('Post Purchase') }}</h1>
      <v-btn color="primary" @click="$router.push({ name: 'website.upsell.post_purchase.create' })" depressed>
        <v-icon>mdi-plus mr-1</v-icon>
        {{ $t('Create') }}
      </v-btn>
    </div>
    <p>
      Showing more potential products after your customers add a product to cart or successfully check out, Upsell offer
      has been proven that it may boost conversion rate to 16%. See how it looks or watch video to know how it works.
    </p>
    <v-card class="pa-4 pb-0">
      <v-text-field
        v-model="table.filter.search"
        prepend-inner-icon="mdi-magnify"
        placeholder="Search"
        @change="getListPostPurchase"
      ></v-text-field>
      <v-data-table
        :loading="table.loading"
        :headers="table.columns"
        :items="table.records"
        :items-per-page="table.pagination.limit"
        :page.sync="table.pagination.page"
        item-key="_id"
        no-data-text="There are no history"
        hide-default-footer
      >
        <template v-slot:[`item.priority`]="{ item }">
          <div style="width: 44px;" class="py-3">
            <v-text-field
              min="1"
              type="number"
              v-model.number="item.priority"
              @blur="item.priority > 0 ? submitPriority(item) : null"
              :error="item.priority < 0 ? true : false"
              hide-details
            ></v-text-field>
          </div>
        </template>
        <template v-slot:[`item.offerName`]="{ item: { offerName, _id } }">
          <a class="primary--text font-weight-bold" @click="onViewPostPurchase(_id)">{{ offerName }}</a>
        </template>
        <template v-slot:[`item.offerType`]="{ item: { offerType } }">
          <span class="text-capitalize">{{ offerType }}</span>
        </template>
        <template v-slot:[`item.productOrCollection`]="{ item: { collectionTargetIds, productTargetIds } }">
          <div v-if="collectionTargetIds == 0 && productTargetIds == 0" class="d-flex align-center">
            <v-chip label color="primary" class="mr-2">A</v-chip>
            Any Product
          </div>
          <span v-else>{{ productTargetIds.length + collectionTargetIds.length }}</span>
        </template>
        <template v-slot:[`item.activated`]="{ item: { activated } }">
          <v-chip v-if="activated" color="success" class="text-capitalize font-weight-bold" label small
            >Activated</v-chip
          >
          <v-chip v-else class="text-capitalize" label small>Deactive</v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item: { _id, activated } }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="!activated" @click="onActions('activate', _id)">
                <v-list-item-title>Active</v-list-item-title>
              </v-list-item>
              <v-list-item v-else @click="onActions('deactivate', _id)">
                <v-list-item-title>Deactivate</v-list-item-title>
              </v-list-item>
              <v-list-item @click="onActions('delete', _id)">
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <div v-if="table.records.length" class="d-flex justify-space-between mt-4">
        <limit-page :callback="setLimit" />
        <v-pagination
          :length="table.pagination.total"
          v-model="table.pagination.page"
          hide
          :total-visible="5"
        ></v-pagination>
      </div>
    </v-card>
    <v-dialog v-model="showModalDelete" width="500">
      <v-card v-if="showModalDelete" class="pa-4">
        <h2>Delete offer?</h2>
        <v-btn icon class="close-icon" @click="showModalDelete = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
        <p class="mb-4">Are you sure you want to delete this offer. This action cannot be reversed.</p>
        <div class="d-flex justify-end" style="gap: 12px">
          <v-btn text @click="showModalDelete = false">Cancel</v-btn>
          <v-btn color="error" :loading="table.loading" depressed @click="onDeletePostPurChase">Delete</v-btn>
        </div>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { offerApi } from '@/apis/offers';
import { convertIdsToArrays } from '@/helpers/convertData';
export default {
  components: {},
  data() {
    return {
      table: {
        loading: false,
        columns: [
          { text: 'Priority', value: 'priority', sortable: false, width: 60 },
          {
            text: 'Offer Name',
            value: 'offerName',
            sortable: false,
          },
          {
            text: 'Product/Collection',
            value: 'productOrCollection',
            sortable: false,
          },
          {
            text: 'Type',
            value: 'offerType',
            sortable: false,
          },
          {
            text: 'Status',
            value: 'activated',
            sortable: false,
          },
          {
            text: 'Actions',
            value: 'actions',
            sortable: false,
          },
        ],
        records: [],
        pagination: {
          limit: 50,
          total: 1,
          page: 1,
          lengthPage: 1,
        },
        filter: {
          search: null,
        },
      },
      showModalDelete: false,
      idSelected: null,
    };
  },
  methods: {
    async getListPostPurchase() {
      try {
        this.table.loading = true;
        const params = {
          page: this.table.pagination.page,
          limit: this.table.pagination.limit,
          offerType: 'postPurchase',
          search: this.table.filter.search,
        };
        const { data } = await offerApi.get(params);
        if (data) {
          const records = data.offers.map(item => {
            return Promise.all([
              convertIdsToArrays('collection', item.collectionRecommendIds),
              convertIdsToArrays('collection', item.collectionTargetIds),
              convertIdsToArrays('product', item.productRecommendIds),
              convertIdsToArrays('product', item.productTargetIds),
              convertIdsToArrays('product', item.handpickedProductIds),
              convertIdsToArrays('collection', item.handpickedCollectionIds),
            ]).then(data => {
              return {
                ...item,
                seeMore: true,
                collectionRecommendIds: data[0],
                collectionTargetIds: data[1],
                productRecommendIds: data[2],
                productTargetIds: data[3],
                handpickedProductIds: data[4],
                handpickedCollectionIds: data[5],
              };
            });
          });
          Promise.all(records).then(data => {
            this.table.records = data;
          });
          const count = await offerApi.count(params);
          this.table.pagination.lengthPage = Math.ceil(count.data.count / this.table.pagination.limit);
        }
      } catch (error) {
        console.log('🚀 ~ file: index.vue:97 ~ getListPostPurchase ~ error:', error);
      } finally {
        this.table.loading = false;
      }
    },
    async submitPriority(item) {
      try {
        await offerApi.update(item._id, { priority: item.priority });
      } catch (error) {
        console.log('🚀 ~ file: index.vue:151 ~ submitPriority ~ error:', error);
      }
    },
    setLimit(limit) {
      this.table.pagination.limit = limit;
      this.getListPostPurchase();
    },
    onViewPostPurchase(id) {
      this.$router.push({ name: 'website.upsell.post_purchase.update', params: { id } });
    },
    async onActions(type, id) {
      if (type === 'delete') {
        this.idSelected = id;
        this.showModalDelete = true;
      } else {
        this.idSelected = null;
        try {
          this.table.loading = true;
          await offerApi.changeActive(type, [id]);
          this.getListPostPurchase();
        } catch (error) {
          console.log(error);
        } finally {
          this.table.loading = false;
        }
      }
    },
    async onDeletePostPurChase() {
      try {
        this.table.loading = true;
        await offerApi.remove(`?ids=${this.idSelected}`);
        this.showModalDelete = false;
        this.getListPostPurchase();
      } catch (error) {
        console.log(error);
      } finally {
        this.table.loading = false;
      }
    },
  },
  created() {
    this.getListPostPurchase();
  },
};
</script>

<style lang="scss" scoped></style>
