var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('redirect',{staticClass:"mb-5",attrs:{"title":"Boost UpSell","route":"website.upsell"}}),_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.$t('Post Purchase')))]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.$router.push({ name: 'website.upsell.post_purchase.create' })}}},[_c('v-icon',[_vm._v("mdi-plus mr-1")]),_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")],1)],1),_c('p',[_vm._v(" Showing more potential products after your customers add a product to cart or successfully check out, Upsell offer has been proven that it may boost conversion rate to 16%. See how it looks or watch video to know how it works. ")]),_c('v-card',{staticClass:"pa-4 pb-0"},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","placeholder":"Search"},on:{"change":_vm.getListPostPurchase},model:{value:(_vm.table.filter.search),callback:function ($$v) {_vm.$set(_vm.table.filter, "search", $$v)},expression:"table.filter.search"}}),_c('v-data-table',{attrs:{"loading":_vm.table.loading,"headers":_vm.table.columns,"items":_vm.table.records,"items-per-page":_vm.table.pagination.limit,"page":_vm.table.pagination.page,"item-key":"_id","no-data-text":"There are no history","hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.table.pagination, "page", $event)}},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3",staticStyle:{"width":"44px"}},[_c('v-text-field',{attrs:{"min":"1","type":"number","error":item.priority < 0 ? true : false,"hide-details":""},on:{"blur":function($event){item.priority > 0 ? _vm.submitPriority(item) : null}},model:{value:(item.priority),callback:function ($$v) {_vm.$set(item, "priority", _vm._n($$v))},expression:"item.priority"}})],1)]}},{key:"item.offerName",fn:function(ref){
var ref_item = ref.item;
var offerName = ref_item.offerName;
var _id = ref_item._id;
return [_c('a',{staticClass:"primary--text font-weight-bold",on:{"click":function($event){return _vm.onViewPostPurchase(_id)}}},[_vm._v(_vm._s(offerName))])]}},{key:"item.offerType",fn:function(ref){
var offerType = ref.item.offerType;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(offerType))])]}},{key:"item.productOrCollection",fn:function(ref){
var ref_item = ref.item;
var collectionTargetIds = ref_item.collectionTargetIds;
var productTargetIds = ref_item.productTargetIds;
return [(collectionTargetIds == 0 && productTargetIds == 0)?_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"mr-2",attrs:{"label":"","color":"primary"}},[_vm._v("A")]),_vm._v(" Any Product ")],1):_c('span',[_vm._v(_vm._s(productTargetIds.length + collectionTargetIds.length))])]}},{key:"item.activated",fn:function(ref){
var activated = ref.item.activated;
return [(activated)?_c('v-chip',{staticClass:"text-capitalize font-weight-bold",attrs:{"color":"success","label":"","small":""}},[_vm._v("Activated")]):_c('v-chip',{staticClass:"text-capitalize",attrs:{"label":"","small":""}},[_vm._v("Deactive")])]}},{key:"item.actions",fn:function(ref){
var ref_item = ref.item;
var _id = ref_item._id;
var activated = ref_item.activated;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[(!activated)?_c('v-list-item',{on:{"click":function($event){return _vm.onActions('activate', _id)}}},[_c('v-list-item-title',[_vm._v("Active")])],1):_c('v-list-item',{on:{"click":function($event){return _vm.onActions('deactivate', _id)}}},[_c('v-list-item-title',[_vm._v("Deactivate")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onActions('delete', _id)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}],null,true)}),(_vm.table.records.length)?_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('limit-page',{attrs:{"callback":_vm.setLimit}}),_c('v-pagination',{attrs:{"length":_vm.table.pagination.total,"hide":"","total-visible":5},model:{value:(_vm.table.pagination.page),callback:function ($$v) {_vm.$set(_vm.table.pagination, "page", $$v)},expression:"table.pagination.page"}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showModalDelete),callback:function ($$v) {_vm.showModalDelete=$$v},expression:"showModalDelete"}},[(_vm.showModalDelete)?_c('v-card',{staticClass:"pa-4"},[_c('h2',[_vm._v("Delete offer?")]),_c('v-btn',{staticClass:"close-icon",attrs:{"icon":""},on:{"click":function($event){_vm.showModalDelete = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1),_c('p',{staticClass:"mb-4"},[_vm._v("Are you sure you want to delete this offer. This action cannot be reversed.")]),_c('div',{staticClass:"d-flex justify-end",staticStyle:{"gap":"12px"}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showModalDelete = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.table.loading,"depressed":""},on:{"click":_vm.onDeletePostPurChase}},[_vm._v("Delete")])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }